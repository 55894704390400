import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

    <Layout>
        <section className="section-home">

            <div id="root">
                <div className="ae af">


                    <main id="main">
                        <div
                            className="wcb3-billboard-cta b5 b6 b7 b8 b9 ba bb ae bx be jg bg bh bi bj c0 bl ji bn c2 bp jk br">
                            <div className="bj bs bt"><br/>
                                <h1>Our exclusive Quotebot allows you to easily search for any type or size
                                    vehicle.</h1><br/>
                                <h4>We are specialists is Pre-Arranged Transportation</h4><br/>
                                <ul style={{listStyleType: 'none'}}>
                                    <li>1. Enter your location and destination, number of Pax and large luggage, you
                                        don’t
                                        need to change the luggage or date. Click Search.
                                    </li>
                                    <li>2. See a list of vehicles from lowest rate, descending, with driver pix, and
                                        all-inclusive rates for each vehicle. Click View More.
                                    </li>
                                    <li>3. Use the Filter to drill down to choose a different Vehicle (make, model,
                                        color, year), Drivers (gender, language(s), experience, FOID background check,
                                        Veteran), and Amenities (baby or booster seat, bike rack, lavatory, Wi-Fi, DVD,
                                        widescreen TV, and amenities.
                                    </li>
                                    <li>4. Choose and Book! Confirmations are sent to you and your driver.</li>
                                    <li>5. If that vehicle and driver is not available due to other business, our
                                        Quotebot will choose the next affiliate that meets your needs and as close the
                                        rate you chose. We will NEVER Surge Price you!
                                    </li>
                                    <li>6. You can even email us for a custom quote if our Quotebot fails to the deliver
                                        a quote.
                                    </li>
                                    <li>7. Your confirmation is immediately sent to that driver for he or she will
                                        Accept or Reject the Booking depending on their schedule. Our drivers are owner
                                        operators or fleets and have their own clients.
                                    </li>
                                    <li>8. If Rejected, we’ll find the next closest vehicle and rate to fit your
                                        requirements. You can always Cancel a Booking if the vehicle, driver or rate are
                                        unacceptable.
                                        Our confirmations will state all your travel details including: destinations,
                                        dates and times, driver name and cell phone, vehicle type, special instructions
                                        and requests, and itemized costs. You can make changes or cancel any time
                                        (please read company policy, terms and conditions at least once to familiarize
                                        procedures). We’re here to assist you in every way! We’ll do everything we can
                                        to earn your repeat business. We value you and our great drivers.
                                        Soon, we’ll offer Shared Rides and On-Demand services.
                                    </li>
                                </ul>


                                <p>These items may be on your invoice:</p>
                                <ul>
                                    <li> All-In Rate</li>
                                    <li> Min / Flag Rate</li>
                                    <li> Mileage Rate</li>
                                    <li> Hourly Rate</li>
                                    <li> Number of hours</li>
                                    <li> Gratuity</li>
                                    <li> Extra Gratuity</li>
                                    <li> AM / PM Rush Hour</li>
                                    <li> Airport min Rate</li>
                                    <li> City min Rate</li>
                                    <li> Extra Stop</li>
                                    <li> Wait Time</li>
                                    <li> Tolls</li>
                                    <li>Late / Early / Holiday Rate</li>
                                    <li> Friday / Saturday Night Rate</li>
                                    <li> Parking</li>
                                    <li>Seaport min Rate</li>
                                    <li> Baggage Meet</li>
                                    <li> Baby Seat</li>
                                    <li> Booster Seat</li>
                                    <li> Bodyguard / Security</li>
                                    <li> Tour Guide</li>
                                    <li> Parking</li>
                                    <li>Airport Arrival Tax</li>
                                    <li> Airport Departure Tax</li>
                                    <li> Seaport Tax</li>
                                    <li>VAT</li>
                                    <li> Congestion Tax</li>
                                    <li> Currency Conversion</li>
                                    <li>Miscellaneous</li>
                                </ul>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    </Layout>
);

export default Page;
